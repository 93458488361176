<!-- @format -->

<template>
  <div>
    <mq-layout :mq="['sm', 'md']">
      <div
        v-if="$i18n.locale === 'de'"
        :class="$mq"
        class="filmwelt-footer__material"
      >
        <a
          class="external"
          href="RusslandVonOben_Filmheft_final.pdf"
          target="_blank"
        >
          <img
            src="@/assets/images/Schulmaterial_Russlandvonoben_mobile.png"
            alt=""
          />
        </a>
      </div>
    </mq-layout>
    <div class="filmwelt-footer" :class="$mq">
      <a class="external" href="http://www.filmweltverleih.de/" target="_blank"
        >FILMWELT HOME</a
      >
      <!-- <a
        href="#"
        class="btn_modal"
        data-rel="#modal_press"
        @click.prevent="emitPressClick()"
        >{{ $t('footer.Presse') }}</a
      > -->
      <a
        class="external"
        href="http://www.filmweltverleih.de/page/impressum"
        target="_blank"
        >{{ $t('footer.Impressum') }}</a
      >
      <a
        class="external"
        href="http://www.filmweltverleih.de/page/datenschutz"
        target="_blank"
        >{{ $t('footer.Datenschutz') }}</a
      >
      <a
        class="external"
        v-if="teilnahmebedingung != 'leer'"
        :href="'/' + teilnahmebedingung"
        target="_blank"
        >TEILNAHMEBEDINGUNGEN</a
      >
    </div>
    <div class="filmwelt-cline">
      {{ $t('footer.Copyright') }}
      <a
        class="text-white external"
        href="http://www.novagraphix.de"
        target="_blank"
        >novagraphix</a
      >
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      teilnahmebedingung: {
        type: String,
        default: 'leer',
      },
    },
    methods: {},
  }
</script>

<style lang="scss">
  .filmwelt-footer {
    $self: &;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1rem;
    justify-content: center;
    text-transform: uppercase;
    &__material {
      margin-bottom: 20px;
      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    a {
      color: white;
      &:hover {
        color: darken($tertiary, 20%);
      }
      &:not(:last-child):after {
        color: white;
        content: '|';
        padding: 0 5px 0 5px;
      }
    }
    &.sm {
      a {
        &:after {
          content: '';
          padding: 0;
        }
        &:last-child {
          margin-left: 0;
        }
      }
    }
    &.md,
    &.lg,
    &.xl,
    &.xxl {
      flex-direction: row;
    }
  }
  .filmwelt-billing {
    width: 100%;
    &.lg,
    &.xl,
    &.xxl {
      margin-bottom: 1.5rem;
      padding: 0 5rem;
    }
    img {
      margin: 0 auto;
      padding: 0 5rem;
      &.sm,
      &.md {
        width: 100%;
        height: auto;
      }
      &.lg,
      &.xl,
      &.xxl {
        width: auto;
      }
    }
  }
  .filmwelt-cline {
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.7rem;
    color: white;
    padding: 0 1rem;
    a {
      color: white;
      &:hover {
        color: darken($tertiary, 20%);
      }
    }
  }
</style>
